const awsconfig = {
  // added BC to enable access to Appsync for more complex update queries
  aws_appsync_graphqlEndpoint: `${process.env.REACT_APP_APPSYNC_API}`,
  aws_appsync_region: `${process.env.REACT_APP_REGION}`,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  Auth: {
    //identityPoolId: `${process.env.REACT_APP_IDENTITY_POOL_ID}`,
    region: `${process.env.REACT_APP_REGION}`,
    identityPoolRegion: `${process.env.REACT_APP_REGION}`,
    userPoolId: `${process.env.REACT_APP_USER_POOL_ID}`,
    userPoolWebClientId: `${process.env.REACT_APP_USER_POOL_CLIENT_ID}`,
    identityPoolId: `${process.env.REACT_APP_IDENTITY_POOL_ID}`,
  },
  Storage: {
    AWSS3: {
      bucket: `${process.env.REACT_APP_UPLOADS_BUCKET}`, //REQUIRED -  Amazon S3 bucket name
      region: 'eu-west-2', // there is only one bucket and its in the UK
      identityPoolId: `${process.env.REACT_APP_IDENTITY_POOL_ID}`,
    },
  },
  API: {
    // API added via CDK to enable access to the usetLoginsManager lambda in edsys
    endpoints: [
      {
        name: 'BPAdminAPI',
        endpoint: `${process.env.REACT_APP_BPADMIN_API}`,
        region: `${process.env.REACT_APP_REGION}`,
      },
    ],
  },
}

export default awsconfig
